<template>
  <div>
      <el-form :rules="rules"
               ref="form"
               :model="form"
               label-position="center"
               label-width="100px">
          <el-row>
              <el-col :span="24">
                  <div class="title-com">
                      <h2 class="title">山东格瑞德集团应聘人员登记表</h2>
                      <div class="intro tit-sim">
                          <p>
                              以下信息仅限公司内部面试使用，我们将全力保护您的隐私，请您放心！
                          </p>
                      </div>
                  </div>
              </el-col>
          </el-row>
          <el-row>
              <el-col class="inp-con" :span="24">
                  <el-form-item prop="applyDate" label="填表日期">
                      <el-date-picker type="date"
                                      style="width: 20%"
                                      v-model="form.applyDate"
                                      value-format="yyyy-MM-dd"
                                      placeholder="选择日期"></el-date-picker>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="20">
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="姓名:" prop="Name">
                              <el-input calss="l-width" v-model="form.Name"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="身份证号:" prop="idCard">
                              <el-input v-model="form.idCard"
                                        maxlength="18"
                                        show-word-limit
                                        placeholder="请输入有效身份证号"
                                        @blur="nocheck"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="出生年月:" prop="birthday">
                              <el-input v-model="form.birthday"
                                        placeholder="填写身份证号自动带出"
                                        :disabled="true"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="性别:">
                              <el-radio-group v-model="form.sex"
                                              calss="l-width"
                                              :disabled="true">
                                  <el-radio label="男"></el-radio>
                                  <el-radio label="女"></el-radio>
                              </el-radio-group>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="民族:" prop="Nationality">
                              <el-input v-model="form.Nationality"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="政治面貌:" prop="politicsStatus">
                              <el-select v-model="form.politicsStatus"
                                         placeholder="选择政治面貌"
                                         style="width: 100%">
                                  <el-option v-for="item in poTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="婚姻状况:" prop="MarriedStatus">
                              <el-select v-model="form.MarriedStatus"
                                         placeholder="选择婚姻状况"
                                         style="width: 100%">
                                  <el-option v-for="item in qyTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="职称证书:" prop="Position">
                              <el-select v-model="form.Position"
                                         placeholder="选择职称证书"
                                         style="width: 100%">
                                  <el-option v-for="item in tiTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="技能证书:" prop="skillCert">
                              <el-select v-model="form.skillCert"
                                         placeholder="选择技能证书">
                                  <el-option v-for="item in jnTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                              <!--<el-input v-model="form.skillCert"></el-input>-->
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="全日制学历 :" prop="FirstDiploma">
                              <el-select v-model="form.FirstDiploma"
                                         placeholder="选择第一学历"
                                         style="width: 100%">
                                  <el-option v-for="item in edTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="非全日制学历:" prop="TopDiploma">
                              <el-select v-model="form.TopDiploma"
                                         placeholder="选择非全日制学历"
                                         style="width: 100%">
                                  <el-option v-for="item in edTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="外语语种及级别:">
                              <el-input v-model="form.LanguageLevel"></el-input>
                          </el-form-item>
                      </el-col>

                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="籍贯:" prop="NativePlace">
                              <v-distpicker class="sele"
                                            @selected="onSelected"></v-distpicker>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="户籍地址:" prop="idAddress">
                              <el-input v-model="form.idAddress"
                                        placeholder="请输入户籍地址"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="户口性质:" prop="NativeStatus">
                              <el-select v-model="form.NativeStatus"
                                         placeholder="选择户口性质"
                                         style="width: 100%">
                                  <el-option v-for="item in adTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>

                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="通讯地址:" prop="Address">
                              <el-input v-model="form.Address"
                                        placeholder="请输入通讯地址"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="手机号码:" prop="MobilePhone">
                              <el-input v-model="form.MobilePhone"
                                        maxlength="11"
                                        show-word-limit
                                        placeholder="请输入有效手机号"
                                        @blur="phonecheck"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="电子邮箱:" prop="Email">
                              <el-input v-model="form.Email"
                                        placeholder="请输入电子邮箱"></el-input>
                          </el-form-item>
                      </el-col>

                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="招聘来源:">
                              <el-select v-model="form.ApplySource"
                                         placeholder="选择招聘来源"
                                         style="width: 100%">
                                  <el-option v-for="item in applyTypeOptions"
                                             :key="item.value"
                                             :label="item.label"
                                             :value="item.value"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="应聘岗位:">
                              <el-input v-model="form.Apply_Job"
                                        placeholder="请输入应聘岗位"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="介绍人:">
                              <el-input v-model="form.IntroducerName"
                                        placeholder="介绍人"></el-input>
                          </el-form-item>
                      </el-col>

                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="紧急联系人/姓名:" prop="Emergency">
                              <el-input v-model="form.Emergency"
                                        placeholder="请输入紧急联系人/姓名"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="紧急联系人/电话:" prop="EmergencyPhone">
                              <el-input v-model="form.EmergencyPhone"
                                        placeholder="请输入紧急联系人/电话"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="可开始工作时间:" prop="ApplyStartDate">
                              <el-date-picker type="date"
                                              v-model="form.ApplyStartDate"
                                              value-format="yyyy-MM-dd"
                                              placeholder="选择可开始工作时间"></el-date-picker>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col class="inp-con" :span="8">
                          <el-form-item label="特殊人员:">
                              <el-select v-model="form.SpecialPersonnel"
                                         multiple
                                         placeholder="可多选"
                                         style="width: 100%">
                                  <el-option label="退伍军人" value="退伍军人"></el-option>
                                  <el-option label="失业人员" value="失业人员"></el-option>
                                  <el-option label="残疾证" value="残疾证"></el-option>
                                  <el-option label="无" value="无"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                  </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center">
                  <el-form-item class="image-con">
                      <el-upload class="avatar-uploader"
                                 action="https://api.gradgroup.cn/ftp/ftp/upload"
                                 :headers ="token"
                                 :show-file-list="false"
                                 :on-success="OSOnSuccess">
                          <img v-if="form.Image_URL" v-lazy="form.Image_URL" class="avatar" />
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                  </el-form-item>
                  <p class="image-url">上传头像</p>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>健康调查<span style="color: red">*</span></p>
                  </div>
              </el-col>
              <el-col :span="12"
                      class="toolbar"
                      style="padding-bottom: 0px; text-align: right">
                  <el-form :inline="true">
                      <el-form-item>
                          <el-button class="add-btn"
                                     type="primary"
                                     v-on:click="diseasehandleAdd">新增</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-row>
          <el-row>
              <el-table :data="form.diseaseArr" style="width: 100%" class="bg-color">
                  <el-table-column prop="diseaseType"
                                   label="病史类型"></el-table-column>
                  <el-table-column prop="isYes" label="状态"></el-table-column>
                  <el-table-column prop="explain" label="说明"></el-table-column>
              </el-table>
          </el-row>
          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>学习经历<span style="color: red">*</span></p>
                  </div>
              </el-col>
              <el-col :span="12"
                      class="toolbar"
                      style="padding-bottom: 0px; text-align: right">
                  <el-form :inline="true">
                      <el-form-item>
                          <el-button class="add-btn"
                                     type="primary"
                                     v-on:click="customerhandleAdd">新增</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-row>
          <el-row>
              <el-table :data="form.educatedArr" style="width: 100%" class="bg-color">
                  <el-table-column prop="EnterTime"
                                   label="开始时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="GraduateTime"
                                   label="结束时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="GradauteSchool"
                                   label="学校名称"></el-table-column>
                  <el-table-column prop="Speciality" label="专业"></el-table-column>
                  <el-table-column prop="Certificate"
                                   label="学历"></el-table-column>
                  <el-table-column prop="Voucher"
                                   label="证明人及电话"></el-table-column>
                  <el-table-column label="操作" width="150">
                      <template scope="scope">
                          <el-button @click="CustomerhandleDetail(scope.row, scope.$index)"
                                     type="text"
                                     size="small">修改</el-button>
                          <el-button size="small"
                                     v-on:click="CustomerhandleDelete(scope.row)"
                                     type="text">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </el-row>

          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>培训经历</p>
                  </div>
              </el-col>
              <el-col :span="12"
                      class="toolbar"
                      style="padding-bottom: 0px; text-align: right">
                  <el-form :inline="true">
                      <el-form-item>
                          <el-button class="add-btn" type="primary" v-on:click="trainAdd">新增</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-row>

          <el-row>
              <el-table :data="form.trainedArr" style="width: 100%" class="bg-color">
                  <el-table-column prop="StartTime"
                                   label="开始时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="EndTime"
                                   label="结束时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="Institutions"
                                   label="培训机构"></el-table-column>
                  <el-table-column prop="Details" label="培训内容"></el-table-column>
                  <el-table-column prop="InCertificate"
                                   label="证书名称"></el-table-column>
                  <el-table-column label="操作" width="150">
                      <template scope="scope">
                          <el-button @click="TrainingExperienceDetail(scope.row, scope.$index)"
                                     type="text"
                                     size="small">修改</el-button>
                          <el-button size="small"
                                     v-on:click="TrainingExperienceDelete(scope.row)"
                                     type="text">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </el-row>
          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>工作经历<span style="color: red">*</span></p>
                  </div>
              </el-col>
              <el-col :span="12"
                      class="toolbar"
                      style="padding-bottom: 0px; text-align: right">
                  <el-form :inline="true">
                      <el-form-item>
                          <el-button class="add-btn" type="primary" v-on:click="workAdd">新增</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-row>
          <el-row>
              <el-table :data="form.workedArr" style="width: 100%" class="bg-color">
                  <el-table-column prop="StartTime"
                                   label="开始时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="EndTime"
                                   label="结束时间"
                                   :formatter="formatStartTime"></el-table-column>
                  <el-table-column prop="WorkAddress"
                                   label="工作单位"></el-table-column>
                  <el-table-column prop="LeavingReason"
                                   label="职位/岗位"></el-table-column>
                  <el-table-column prop="BecaseWork" label="离职原因"></el-table-column>
                  <el-table-column prop="WorkVoucher"
                                   label="证明人及电话"></el-table-column>
                  <el-table-column label="操作" width="150">
                      <template scope="scope">
                          <el-button @click="WorkExperienceDetail(scope.row, scope.$index)"
                                     type="text"
                                     size="small">修改</el-button>
                          <el-button size="small"
                                     v-on:click="WorkExperienceDelete(scope.row)"
                                     type="text">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </el-row>
          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>家庭成员<span style="color: red">*</span></p>
                  </div>
              </el-col>
              <el-col :span="12"
                      class="toolbar"
                      style="padding-bottom: 0px; text-align: right">
                  <el-form :inline="true">
                      <el-form-item>
                          <el-button class="add-btn" type="primary" v-on:click="familyAdd">新增</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-row>

          <el-row>
              <el-table :data="form.familyArr" style="width: 100%" class="bg-color">
                  <el-table-column prop="FaName" label="姓名"></el-table-column>
                  <el-table-column prop="Relationship"
                                   label="与本人关系"></el-table-column>
                  <el-table-column prop="WorkDpt" label="职位/岗位"></el-table-column>
                  <el-table-column prop="WorkUnits" label="工作单位"></el-table-column>
                  <el-table-column prop="PhoneNo" label="联系电话"></el-table-column>=
                  <el-table-column label="操作" width="150">
                      <template scope="scope">
                          <el-button @click="FamilyDetail(scope.row, scope.$index)"
                                     type="text"
                                     size="small">修改</el-button>
                          <el-button size="small"
                                     v-on:click="FamilyDelete(scope.row)"
                                     type="text">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <el-form-item label="备注:">
                      <el-input class="textarea-con"
                                type="textarea"
                                v-model="form.Remark"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="12" class="list-title">
                  <div class="intro">
                      <p>知识产权情况调查<span style="color: red">*</span></p>
                  </div>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="6">
                  <span>在原单位工作的岗位是否涉及知识产权:</span>
              </el-col>
              <el-col :span="18">
                  <el-form-item label="">
                      <el-switch v-model="form.IsIPR"
                                 active-text="是"
                                 inactive-text="否">
                      </el-switch>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <span>请具体列举收及的知识产权内容:</span>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <!--<span>请具体列举收及的知识产权内容:</span>-->
                  <el-input type="textarea" v-model="form.IPRContent"></el-input>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="6">
                  <span>是否与原单位签订过竞业限制条款:</span>
              </el-col>
              <el-col :span="18">
                  <el-form-item label="">
                      <el-switch v-model="form.IsAgreement"
                                 active-text="是"
                                 inactive-text="否">
                      </el-switch>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row>
              <span>请写明竞业限制条款的生效时间和年限:</span>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <!--<span>请写明竞业限制条款的生效时间和年限:</span>-->
                  <el-input type="textarea" v-model="form.AgreementContent"></el-input>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <el-form-item style="text-align: center">
                      <el-button class="btn-left"
                                 type="primary"
                                 @click="submitForm('form')">提交</el-button>
                      <el-button class="btn-right" @click="resetForm('form')">重置</el-button>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>

    <el-dialog
      title="新增学习经历"
      :visible.sync="CustomeraddFormVisible"
      v-model="CustomeraddFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        :rules="CustomeraddFormRules"
        ref="CustomeraddForm"
        :model="CustomeraddForm"
      >
        <el-form-item label="开始时间:" prop="EnterTime">
          <el-date-picker
            v-model="CustomeraddForm.EnterTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间:" prop="GraduateTime">
          <el-date-picker
            v-model="CustomeraddForm.GraduateTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="学校名称:" prop="GradauteSchool">
          <el-input v-model="CustomeraddForm.GradauteSchool"></el-input>
        </el-form-item>
        <el-form-item label="专业:" prop="Speciality">
          <el-input v-model="CustomeraddForm.Speciality"></el-input>
        </el-form-item>
        <el-form-item label="学历:" prop="Certificate">
          <el-input v-model="CustomeraddForm.Certificate"></el-input>
        </el-form-item>
        <el-form-item label="证明人及电话">
          <el-input v-model="CustomeraddForm.Voucher"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="CustomeraddFormVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            v-on:click="CustomeraddSubmit('CustomeraddForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="新增培训经历"
      :visible.sync="TrainingExperienceaddFormVisible"
      v-model="TrainingExperienceaddFormVisible"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px" ref="TrainaddForm"  :model="TrainaddForm">
        <el-form-item label="开始时间:" prop="StartTime" :rules="[{ required: true, message: '请选择开始时间', trigger: 'blur' }]">
          <el-date-picker
            v-model="TrainaddForm.StartTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间:" prop="EndTime" :rules="[{ required: true, message: '请选择结束时间', trigger: 'blur', }, ]">
          <el-date-picker
            v-model="TrainaddForm.EndTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="培训机构:">
          <el-input v-model="TrainaddForm.Institutions"></el-input>
        </el-form-item>
        <el-form-item label="培训内容:">
          <el-input type="textarea" v-model="TrainaddForm.Details"></el-input>
        </el-form-item>
        <el-form-item label="证书名称:">
          <el-input v-model="TrainaddForm.InCertificate"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button v-on:click="TrainingExperienceaddFormVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            v-on:click="TrainaddSubmit('TrainaddSubmit')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="新增工作经历"
      :visible.sync="WorkExperienceaddFormVisible"
      v-model="WorkExperienceaddFormVisible"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px" ref="WorkaddForm" :model="WorkaddForm">
        <el-form-item label="开始时间:"  prop="StartTime" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-date-picker
            v-model="WorkaddForm.StartTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间:"  prop="EndTime" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-date-picker
            v-model="WorkaddForm.EndTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="工作单位:" prop="WorkAddress" :rules="[{ required: true, message: '请填写工作单位', trigger: 'blur' }]">
          <el-input v-model="WorkaddForm.WorkAddress"></el-input>
        </el-form-item>
        <el-form-item label="职位/岗位:" prop="LeavingReason" :rules="[{ required: true, message: '请填写职位/岗位', trigger: 'blur' }]">
          <el-input v-model="WorkaddForm.LeavingReason"></el-input>
        </el-form-item>
        <el-form-item label="离职原因:" prop="BecaseWork" :rules="[{ required: true, message: '请填写离职原因', trigger: 'blur' }]">
          <el-input type="textarea" v-model="WorkaddForm.BecaseWork"></el-input>
        </el-form-item>
        <el-form-item label="证明人及电话:">
          <el-input v-model="WorkaddForm.WorkVoucher"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button v-on:click="WorkExperienceaddFormVisible = false"
            >取消</el-button
          >
          <el-button type="primary" v-on:click="WorkaddSubmit('WorkaddSubmit')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="新增家庭成员"
      :visible.sync="FamilyaddFormVisible"
      v-model="FamilyaddFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        :rules="FamilyaddFormRules"
        ref="FamilyaddForm"
        :model="FamilyaddForm"
      >
        <el-form-item label="姓名:" prop="FaName">
          <el-input v-model="FamilyaddForm.FaName"></el-input>
        </el-form-item>
        <el-form-item label="与本人关系:" prop="Relationship">
          <el-input v-model="FamilyaddForm.Relationship"></el-input>
        </el-form-item>
        <el-form-item label="职位/岗位:" prop="WorkDpt">
          <el-input v-model="FamilyaddForm.WorkDpt"></el-input>
        </el-form-item>
        <el-form-item label="工作单位:" prop="WorkUnits">
          <el-input v-model="FamilyaddForm.WorkUnits"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="PhoneNo">
          <el-input v-model="FamilyaddForm.PhoneNo"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="FamilyaddFormVisible = false">取消</el-button>
          <el-button
            type="primary"
            v-on:click="FamilyaddSubmit('FamilyaddSubmit')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="新增健康状况"
      :visible.sync="DiseaseArrFormVisible"
      v-model="DiseaseArrFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="DiseaseArrForm" :model="DiseaseArrForm" lable-width="150px">
        <el-form-item
          label="有无家族病史:"
          prop="familyState"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-row>
            <el-col :span="8">
              <el-select
                v-model="DiseaseArrForm.familyState"
                placeholder="请选择"
              >
                <el-option label="有" value="有"></el-option>
                <el-option label="无" value="无"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="DiseaseArrForm.familyReson"
                placeholder="情况说明"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="有无传染病史:"
          prop="contagionState"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-row>
            <el-col :span="8">
              <el-select
                v-model="DiseaseArrForm.contagionState"
                placeholder="请选择"
              >
                <el-option label="有" value="有"></el-option>
                <el-option label="无" value="无"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="DiseaseArrForm.contagionReson"
                placeholder="情况说明"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="有无残疾病史:"
          prop="disabilityState"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-row>
            <el-col :span="8">
              <el-select
                v-model="DiseaseArrForm.disabilityState"
                placeholder="请选择"
              >
                <el-option label="有" value="有"></el-option>
                <el-option label="无" value="无"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="DiseaseArrForm.disabilityReson"
                placeholder="情况说明"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="有无职业病史:"
          prop="occupationState"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-row>
            <el-col :span="8">
              <el-select
                v-model="DiseaseArrForm.occupationState"
                placeholder="请选择"
              >
                <el-option label="有" value="有"></el-option>
                <el-option label="无" value="无"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="DiseaseArrForm.occupationReson"
                placeholder="情况说明"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="有无先天疾病:"
          prop="congenitalState"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-row>
            <el-col :span="8">
              <el-select
                v-model="DiseaseArrForm.congenitalState"
                placeholder="请选择"
              >
                <el-option label="有" value="有"></el-option>
                <el-option label="无" value="无"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="DiseaseArrForm.congenitalReson"
                placeholder="情况说明"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row style="text-align: center">
            <el-button v-on:click="DiseaseArrFormVisible = false"
              >取消</el-button
            >
            <el-button
              type="primary"
              v-on:click="DiseaseArraddSubmit('DiseaseArraddSubmit')"
              >提交</el-button
            >
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as validate from "../../util/validate";
import VDistpicker from "v-distpicker";
    import {
        AddUserBaseInfo,
        AddUserBaseInfoLogIn
    } from "../api/hr";
import util from "../../util/date";
export default {
  components: {
    VDistpicker,
  },
  data() {
      return {
          token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
      CustomeraddFormVisible: false,
      TrainingExperienceaddFormVisible: false,
      WorkExperienceaddFormVisible: false,
      FamilyaddFormVisible: false,
      DiseaseArrFormVisible: false,
      form: {
        Name: null,
        birthday: null,
        NativePlace: "",
        Image_URL: "",
        sex: "",
        applyDate: new Date(),
        IsSchool: "否",
        Nationality: "",
        MarriedStatus: "未婚",
        FirstDiploma: "",
        Position: "",
        LanguageLevel: "",
        Health: "",
        TopDiploma: "",
        MobilePhone: "",
        politicsStatus: "",
        NativeStatus: "本市城镇",
        idCard: "",
        IntroducerName: "",
        idAddress: "",
        Email: "",
        Address: "",
        address_tel: "",
        ApplySource: "其他",
        Emergency: "",
        EmergencyPhone: "",
        Apply_Job: "",
        ApplyStartDate: "",
        Apply_JobType: "",
        educatedArr: [],
        trainedArr: [], //培训经历
        workedArr: [], //工作经历
        familyArr: [], //家庭成员
        diseaseArr: [],
        Remark: "",
        SpecialPersonnel: "",
      },
      DiseaseArrForm: {
        family: "有无家族病史",
        familyState: null,
        familyReson: null,
        contagion: "有无传染病史",
        contagionState: null,
        contagionReson: null,
        disability: "有无残疾",
        disabilityState: null,
        disabilityReson: null,
        occupation: "有无职业病史",
        occupationState: null,
        occupationReson: null,
        congenital: "有无职业病史",
        congenitalState: null,
        congenitalReson: null,
      },
      CustomeraddForm: {
        EnterTime: null,
        GraduateTime: null,
        GradauteSchool: null,
        Speciality: null,
        Certificate: null,
        Voucher: null,
      },
      TrainaddForm: {
        StartTime: null,
        EndTime: null,
        Institutions: null,
        Details: null,
        InCertificate: null,
      },
      WorkaddForm: {
        StartTime: null,
        EndTime: null,
        WorkAddress: null,
        LeavingReason: null,
        BecaseWork: null,
        WorkVoucher: null,
      },
      FamilyaddForm: {
        FaName: null,
        Relationship: null,
        WorkDpt: null,
        WorkUnits: null,
        PhoneNo: null,
      },
      qyTypeOptions: [
        {
          value: "未婚",
          label: "未婚",
        },
        {
          value: "已婚",
          label: "已婚",
        },
        {
          value: "丧偶",
          label: "丧偶",
        },
        {
          value: "离异",
          label: "离异",
        },
      ],
      edTypeOptions: [
         {
          value: "无",
          label: "无",
        },
        {
          value: "初中以下",
          label: "初中以下",
        },
        {
          value: "初中",
          label: "初中",
        },
        {
          value: "高中",
          label: "高中",
        },
        {
          value: "中专",
          label: "中专",
        },
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          value: "研究生",
          label: "研究生",
        },
        {
          value: "博士",
          label: "博士",
        },
        {
          value: "博士后",
          label: "博士后",
        },
      ],
      tiTypeOptions: [
        {
          value: "无",
          label: "无",
        },
        {
          value: "助理工程师",
          label: "助理工程师",
        },
        {
          value: "中级工程师",
          label: "中级工程师",
        },
        {
          value: "高级工程师",
          label: "高级工程师",
        },
        {
          value: "初级会计师",
          label: "初级会计师",
        },
        {
          value: "中级会计师",
          label: "中级会计师",
        },
        {
          value: "高级会计师",
          label: "高级会计师",
        },
        {
          value: "初级经济师",
          label: "初级经济师",
        },
        {
          value: "中级经济师",
          label: "中级经济师",
        },
        {
          value: "高级经济师",
          label: "高级经济师",
        },
        ],
        jnTypeOptions: [
            {
                value: "无",
                label: "无",
            },
            {
                value: "初级技工",
                label: "初级技工",
            },
            {
                value: "中级技工",
                label: "中级技工",
            },
            {
                value: "高级技工",
                label: "高级技工",
            },
            {
                value: "技师",
                label: "技师",
            },
            {
                value: "高级技师",
                label: "高级技师",
            },],
      poTypeOptions: [
        {
          value: "群众",
          label: "群众",
        },
        {
          value: "团员",
          label: "团员",
        },
        {
          value: "党员",
          label: "党员",
        },
        {
          value: "无党派人士",
          label: "无党派人士",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      adTypeOptions: [
        {
          value: "本市城镇",
          label: "本市城镇",
        },
        {
          value: "本市农村",
          label: "本市农村",
        },
        {
          value: "外省/市城镇",
          label: "外省/市城镇",
        },
        {
          value: "外省/市农村",
          label: "外省/市农村",
        },
      ],
      applyTypeOptions: [
        {
          value: "58同城",
          label: "58同城",
        },
        {
          value: "门口招聘栏",
          label: "门口招聘栏",
        },
        {
          value: "本公司员工介绍",
          label: "本公司员工介绍",
        },
        {
          value: "智联招聘",
          label: "智联招聘",
        },
        {
          value: "校园招聘会",
          label: "校园招聘会",
        },
        {
          value: "格瑞德官网",
          label: "格瑞德官网",
        },
        {
          value: "BOSS直聘",
          label: "BOSS直聘",
        },
        {
          value: "微信公众号",
          label: "微信公众号",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      rules: {
        Name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        applyDate: [
          {
            required: true,
            message: "请选择填表日期",
            trigger: "blur",
          },
        ],
        ApplyStartDate: [
          {
            required: true,
            message: "请选择可开始工作日期",
            trigger: "blur",
          },
        ],
        Emergency:[
          {
            required:true,
            message:"请填写紧急联系人/姓名",
            trigger:"blur",
          }
        ],
        EmergencyPhone:[
          {
            required:true,
            message:"请填写紧急联系人/电话",
            trigger:"blur",
          }
        ],
        NativePlace: [
          {
            required: true,
            message: "请输入籍贯",
            trigger: "blur",
          },
        ],
        Nationality: [
          {
            required: true,
            message: "请输入民族",
            trigger: "blur",
          },
        ],
        MarriedStatus: [
          {
            required: true,
            message: "请选择婚姻状况",
            trigger: "blur",
          },
        ],
        FirstDiploma: [
          {
            required: true,
            message: "请选择第一学历",
            trigger: "blur",
          },
        ],
        Position: [
          {
            required: true,
            message: "请选择职称",
            trigger: "blur",
          },
        ],
        TopDiploma: [
          {
            required: true,
            message: "请选择非全日制学历",
            trigger: "blur",
          },
        ],
        MobilePhone: [
          {
            required: true,
            message: "请填写手机号",
            trigger: "blur",
          },
        ],
        Email: [
          {
            required: true,
            message: "请填写电子邮箱",
            trigger: "blur",
          },
        ],
        politicsStatus: [
          {
            required: true,
            message: "请填写政治面貌",
            trigger: "blur",
          },
        ],
        NativeStatus: [
          {
            required: true,
            message: "请选择户口性质",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: "请填写身份证号",
            trigger: "blur",
          },
        ],
        idAddress: [
          {
            required: true,
            message: "请填写户籍地址",
            trigger: "blur",
          },
        ],
        Address: [
          {
            required: true,
            message: "请填写通讯地址",
            trigger: "blur",
          },
        ],
      },
      CustomeraddFormRules: {
        //学习经历验证
        EnterTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "blur",
          },
        ],
        GraduateTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "blur",
          },
        ],
        GradauteSchool: [
          {
            required: true,
            message: "请填写学校名称",
            trigger: "blur",
          },
        ],
        Speciality:[
          {
            required: true,
            message: "请填写专业",
            trigger: "blur",
          }
        ],
        Certificate:[
          {
            required: true,
            message: "请填写学历",
            trigger: "blur",
          }
        ],
        Voucher: [
          {
            required: true,
            message: "请填写证明人及电话",
            trigger: "blur",
          },
        ],
      },
      FamilyaddFormRules: {
        //家庭成员验证
        FaName: [
          {
            required: true,
            message: "请填写联系人姓名",
            trigger: "blur",
          },
        ],
        Relationship: [
          {
            required: true,
            message: "请填写与本人关系",
            trigger: "blur",
          },
        ],
        WorkDpt:[
          {
            required: true,
            message: "请填写职位/岗位",
            trigger: "blur",
          }
        ],
        WorkUnits:[
          {
            required: true,
            message: "请填写工作单位",
            trigger: "blur",
          }
        ],
        PhoneNo: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //头像上传成功
    OSOnSuccess(response, file, fileList) {
      this.form.Image_URL =
          response.data.httpHost + response.data.filePath;
    },
    onSelected(data) {
      this.form.NativePlace =
        data.province.value + data.city.value + data.area.value;
      //   alert(data.province + ' | ' + data.city + ' | ' + data.area)
      //   console.log(data)
    },
    //头像上传附件数量控制
    OSFileexceed(files, fileList) {
      this.$message({
        message: "当前上传最大数量为1个。",
        type: "warning",
      });
    },

    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    submitForm(formName) {
      // console.log(this.form)
      // this.form.SpecialPersonnel = this.form.SpecialPersonnel.join(",")
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this[formName].educatedArr.length == 0) {
            this.$message({
              message: "请填写学习经历",
              type: "error",
              duration: 5000,
            });
            return false;
          } else if (this[formName].familyArr.length == 0) {
            this.$message({
              message: "请至少填写一条家庭成员信息",
              type: "error",
              duration: 5000,
            });
            return false;
          } else if (this[formName].diseaseArr.length == 0) {
            this.$message({
              message: "请填写健康调查",
              type: "error",
              duration: 5000,
            });
            return false;
          } else {
            this.$confirm("确定要提交吗？", "提示", {}).then(() => {
              let para = Object.assign({}, this.form);
              if (
                para.SpecialPersonnel != "" &&
                para.SpecialPersonnel.length > 0
              ) {
                para.SpecialPersonnel = para.SpecialPersonnel.join(",");
              } else {
                para.SpecialPersonnel = "";
                }
                let type = this.$route.query.Type;
                console.log(this.$route)
                //alert(this.$route.query.Type)
                if (type == "noLogIn") {
                    AddUserBaseInfo(para).then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            this.$message({
                                message: "登记成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.form = {
                                Name: null,
                                birthday: null,
                                NativePlace: "",
                                Image_URL: "",
                                sex: "男",
                                applyDate: "",
                                IsSchool: "否",
                                Nationality: "",
                                MarriedStatus: "未婚",
                                FirstDiploma: "",
                                Position: "",
                                LanguageLevel: "",
                                Health: "",
                                TopDiploma: "",
                                MobilePhone: "",
                                politicsStatus: "",
                                NativeStatus: "本市城镇",
                                idCard: "",
                                idAddress: "",
                                Email: "",
                                Address: "",
                                address_tel: "",
                                ApplySource: "其他",
                                Emergency: "",
                                EmergencyPhone: "",
                                Apply_Job: "",
                                ApplyStartDate: "",
                                Apply_JobType: "",
                                educatedArr: [],
                                trainedArr: [], //培训经历
                                workedArr: [], //工作经历
                                familyArr: [], //家庭成员
                                diseaseArr: [],
                                SpecialPersonnel: "",
                                Remark: "",
                            };
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                }
                else {
                    AddUserBaseInfoLogIn(para).then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            this.$message({
                                message: "登记成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.form = {
                                Name: null,
                                birthday: null,
                                NativePlace: "",
                                Image_URL: "",
                                sex: "男",
                                applyDate: "",
                                IsSchool: "否",
                                Nationality: "",
                                MarriedStatus: "未婚",
                                FirstDiploma: "",
                                Position: "",
                                LanguageLevel: "",
                                Health: "",
                                TopDiploma: "",
                                MobilePhone: "",
                                politicsStatus: "",
                                NativeStatus: "本市城镇",
                                idCard: "",
                                idAddress: "",
                                Email: "",
                                Address: "",
                                address_tel: "",
                                ApplySource: "其他",
                                Emergency: "",
                                EmergencyPhone: "",
                                Apply_Job: "",
                                ApplyStartDate: "",
                                Apply_JobType: "",
                                educatedArr: [],
                                trainedArr: [], //培训经历
                                workedArr: [], //工作经历
                                familyArr: [], //家庭成员
                                diseaseArr: [],
                                SpecialPersonnel: "",
                                Remark: "",
                            };
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                }
            });
          }
        } else {
          this.$message({
            message: "请检查必填项是否填写",
            type: "error",
            duration: 5000,
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    phonecheck() {
      if (!validate.isMobile(this.form.MobilePhone)) {
        this.$message({
          message: "该输入无效,请输入其他有效手机号",
          type: "error",
          duration: 5000,
        });
        this.form.MobilePhone = null;
        return;
      }
    },
    // nocheck() {
    // 	if (!validate.validateIdNo(this.form.idCard)) {
    // 		this.$message({
    // 			message: '该输入无效,请输入有效身份证号',
    // 			type: 'error',
    // 			duration: 5000
    // 		});
    // 		this.form.idCard = null;
    // 		return;
    // 	}
    //  go(this.form.idCard)
    // },
    // 实现自动生成生日，性别，年龄
    async nocheck() {
      let regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      //如果通过该验证，说明身份证格式正确，但准确性还需计算
      if (regIdCard.test(this.form.idCard)) {
        if (this.form.idCard.length == 18) {
          var idCardWi = new Array(
            7,
            9,
            10,
            5,
            8,
            4,
            2,
            1,
            6,
            3,
            7,
            9,
            10,
            5,
            8,
            4,
            2
          ); //将前17位加权因子保存在数组里
          var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
          var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
          for (var i = 0; i < 17; i++) {
            idCardWiSum += this.form.idCard.substring(i, i + 1) * idCardWi[i];
          }
          var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
          var idCardLast = this.form.idCard.substring(17); //得到最后一位身份证号码
          //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
          if (idCardMod == 2) {
            if (idCardLast == "X" || idCardLast == "x") {
              await this.go(this.form.idCard.length);
            } else {
              this.$message({
                message: "该输入无效,请输入有效身份证号",
                type: "error",
                duration: 5000,
              });
              this.form.idCard = null;
              return;
            }
          } else {
            //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
              await this.go(this.form.idCard.length);
            } else {
              this.$message({
                message: "该输入无效,请输入有效身份证号",
                type: "error",
                duration: 5000,
              });
              this.form.idCard = null;
              return;
            }
          }
        }
      } else {
        this.$message({
          message: "该输入无效,请输入有效身份证号",
          type: "error",
          duration: 5000,
        });
        this.form.idCard = null;
        return;
      }
    },

    // 实现自动生成生日，性别，年龄
    go(val) {
      let iden = this.form.idCard;
      let sex = null;
      let birth = null;
      let myDate = new Date();
      let month = myDate.getMonth() + 1;
      let day = myDate.getDate();
      let age = 0;

      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }

      if (sex % 2 === 0) sex = "女";
      else sex = "男";
      this.form.sex = sex;
      // this.baseInfo.age = age;
      this.form.birthday = birth;
      // this.baseInfo.birthplace = this.area[iden.substring(0,2)];
    },

    //学习经历添加
    customerhandleAdd() {
      this.CustomeraddFormVisible = true;
      this.CustomeraddForm = {
        EnterTime: null,
        GraduateTime: null,
        GradauteSchool: null,
        Speciality: null,
        Certificate: null,
        Voucher: null,
      };
      this.$nextTick(() => {
        this.$refs.CustomeraddForm.clearValidate();
      });
    },
    diseasehandleAdd() {
      this.DiseaseArrForm = {
        family: "有无家族病史",
        familyState: null,
        familyReson: null,
        contagion: "有无传染病史",
        contagionState: null,
        contagionReson: null,
        disability: "有无残疾",
        disabilityState: null,
        disabilityReson: null,
        occupation: "有无职业病史",
        occupationState: null,
        occupationReson: null,
        congenital: "有无先天疾病",
        congenitalState: null,
        congenitalReson: null,
      };
      this.DiseaseArrFormVisible = true;
    },
    //学习经历添加提交
    CustomeraddSubmit(formName) {
      let _this = this;
      this.$refs.CustomeraddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.CustomeraddForm);
          if (this.CustomeraddForm.rowIndex != undefined) {
            this.$set(
              this.form.educatedArr,
              this.CustomeraddForm.rowIndex,
              para
            );
          } else {
            this.form.educatedArr.push(para);
          }

          this.CustomeraddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    CustomerhandleDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.form.educatedArr.splice(this.form.educatedArr.indexOf(row), 1);
      });
    },
    CustomerhandleDetail(row, index) {
      this.CustomeraddForm = Object.assign({}, row);
      this.CustomeraddForm.rowIndex = index;
      this.CustomeraddFormVisible = true;
    },
      trainAdd() {

          
      this.TrainingExperienceaddFormVisible = true;

      this.TrainaddForm = {
        StartTime: null,
        EndTime: null,
        Institutions: null,
        Details: null,
        InCertificate: null,
      };
      this.$nextTick(() => {
        this.$refs.TrainaddForm.clearValidate();
      });
    },
    TrainaddSubmit(formName) {
      let _this = this;
      this.$refs.TrainaddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.TrainaddForm);
          if(!this.TrainaddForm.StartTime || !this.TrainaddForm.EndTime){
 this.$message({
            message: "请填写开始时间和结束时间!",
            type: "error",
          });
          return;
          }
          if (this.TrainaddForm.rowIndex != undefined) {
            this.$set(this.form.trainedArr, this.TrainaddForm.rowIndex, para);
          } else {
            this.form.trainedArr.push(para);
          }

          this.TrainingExperienceaddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    TrainingExperienceDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.form.trainedArr.splice(this.form.trainedArr.indexOf(row), 1);
      });
    },
    TrainingExperienceDetail(row, index) {
      this.TrainaddForm = Object.assign({}, row);
      this.TrainaddForm.rowIndex = index;
      this.TrainingExperienceaddFormVisible = true;
    },
    workAdd() {
      this.WorkExperienceaddFormVisible = true;
      this.WorkaddForm = {
        StartTime: null,
        EndTime: null,
        WorkAddress: null,
        LeavingReason: null,
        BecaseWork: null,
        WorkVoucher: null,
      };
      this.$nextTick(() => {
        this.$refs.WorkaddForm.clearValidate();
      });
    },
    WorkaddSubmit(formName) {
      let _this = this;
      this.$refs.WorkaddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.WorkaddForm);
          if (this.WorkaddForm.rowIndex != undefined) {
            this.$set(this.form.workedArr, this.WorkaddForm.rowIndex, para);
          } else {
            this.form.workedArr.push(para);
          }

          this.WorkExperienceaddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    WorkExperienceDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.form.workedArr.splice(this.form.workedArr.indexOf(row), 1);
      });
    },
    WorkExperienceDetail(row, index) {
      this.WorkaddForm = Object.assign({}, row);
      this.WorkaddForm.rowIndex = index;
      this.WorkExperienceaddFormVisible = true;
    },
    familyAdd() {
      this.FamilyaddFormVisible = true;
      this.FamilyaddForm = {
        FaName: null,
        Relationship: null,
        WorkDpt: null,
        WorkUnits: null,
        PhoneNo: null,
      };
      this.$nextTick(() => {
        this.$refs.FamilyaddForm.clearValidate();
      });
    },
    FamilyaddSubmit(formName) {
      let _this = this;
      this.$refs.FamilyaddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.FamilyaddForm);
          if (this.FamilyaddForm.rowIndex != undefined) {
            this.$set(this.form.familyArr, this.FamilyaddForm.rowIndex, para);
          } else {
            this.form.familyArr.push(para);
          }

          this.FamilyaddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    DiseaseArraddSubmit(formName) {
      let _this = this;
      this.$refs.DiseaseArrForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.DiseaseArrForm);
          var addArray = [];

          addArray.push({
            diseaseType: this.DiseaseArrForm.family,
            isYes: this.DiseaseArrForm.familyState,
            explain: this.DiseaseArrForm.familyReson,
          });
          addArray.push({
            diseaseType: this.DiseaseArrForm.contagion,
            isYes: this.DiseaseArrForm.contagionState,
            explain: this.DiseaseArrForm.contagionReson,
          });
          addArray.push({
            diseaseType: this.DiseaseArrForm.disability,
            isYes: this.DiseaseArrForm.disabilityState,
            explain: this.DiseaseArrForm.disabilityReson,
          });
          addArray.push({
            diseaseType: this.DiseaseArrForm.occupation,
            isYes: this.DiseaseArrForm.occupationState,
            explain: this.DiseaseArrForm.occupationReson,
          });
          addArray.push({
            diseaseType: this.DiseaseArrForm.congenital,
            isYes: this.DiseaseArrForm.congenitalState,
            explain: this.DiseaseArrForm.congenitalReson,
          });
          this.form.diseaseArr = addArray;

          this.DiseaseArrFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    FamilyDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.form.familyArr.splice(this.form.familyArr.indexOf(row), 1);
      });
    },
    FamilyDetail(row, index) {
      this.FamilyaddForm = Object.assign({}, row);
      this.FamilyaddForm.rowIndex = index;
      this.FamilyaddFormVisible = true;
    },
  },
};
</script>

<style>
.title {
  font-size: 20px;
  padding: 10px;
  text-align: center;
  color: #202020;
}

.intro {
  font-size: 14px;
  text-align: left;
  text-align: center;
}

.list-title {
  padding-left: 10px;
}
.list-title .intro {
  text-align: left;
  color: #e81961;
  font-size: 16px;
  font-weight: bold;
}

.image-con {
  margin-bottom: 0 !important;
}

.intro.tit-sim {
  padding: 10px 0;
}

.title-com {
  margin-bottom: 20px;
}

.image-url {
  padding: 0 !important;
  width: 68%;
  text-align: center;
  float: right;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.bg-color {
  margin-bottom: 30px;
}

.bg-color thead tr th {
  background-color: #dce4f1;
  color: #2c2326;
}

.add-btn {
  background-color: #eb1964;
  border-color: #eb1964;
}

.textarea-con textarea {
  height: 82px;
}

.btn-left {
  background-color: #eb1964;
  border-color: #eb1964;
  font-weight: bold;
  width: 135px;
}

.btn-right {
  background-color: #dce4f1;
  color: #221e1b;
  font-weight: bold;
  width: 135px;
}

.inp-con label {
  width: 140px !important;
}

.inp-con .el-form-item__content {
  margin-left: 145px !important;
}

.inp-con .el-radio {
  width: 50px !important;
}

.inp-con .el-radio__input.is-checked .el-radio__inner {
  border-color: #eb1964;
  background: #eb1964;
}

.inp-con .el-radio__input.is-checked + .el-radio__label {
  color: #eb1964 !important;
}

.sele select {
  width: 32%;
}
</style>
